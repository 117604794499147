<template>
	<div>
		<pui-datatable
			v-if="masterDetail"
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
			:actions="actions"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filter()"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
	</div>
</template>

<script>
import wastenotificationsActions from './WastenotificationsActions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'wastenotifications-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'wastenotifications',
			pkAttribute: 'notificationid',
			actions: wastenotificationsActions.actions,
			actionsMasterDetail: wastenotificationsActions.actionsMasterDetail,
			modelColumnDefs: {}
		};
	},
	methods: {
		filter() {
			let group1 = {
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'statuswaste', op: 'ne', data: '1' }]
			};

			let groups = [group1];
			if (this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS) {
				this.$store.getters.getSession.properties.GESTAUT_PROPERTIES_USER_RELATED_ORGS.forEach((org) => {
					groups.push({
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'statuswaste', op: 'eq', data: '1' },
							{
								field: 'creausercompany',
								op: 'cn',
								data: org
							},
							{
								field: 'creausercompany',
								op: 'nn'
							}
						]
					});
				});
			}

			const filter = { groupOp: 'or', rules: [], groups: groups };

			return filter;
		}
	}
};
</script>
